<template>
    <div>
        <div class="mz-query-content">
            <el-form :inline="true" :model="queryData" class="demo-form-inline query-wrap" @submit.native.prevent>
                <el-form-item label="资源名称" :label-width="formLabelWidth">
                    <el-input v-model="queryData.name" placeholder="请输入资源名称"></el-input>
                </el-form-item>
                <el-form-item label="资源状态" :label-width="formLabelWidth">
                    <el-select clearable v-model="queryData.active" placeholder="资源状态" value="">
                        <el-option label="显示" value="1"></el-option>
                        <el-option label="隐藏" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" native-type="submit" @click="doQueryList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="mz-table-content">
            <el-row class="header-button">
                <el-col>
                    <el-button type="primary" icon="el-icon-plus" size="mini"
                               @click="handleAdd" v-has-permission="['sys:resource:add']">新增
                    </el-button>
                    <el-button type="warning" icon="el-icon-download" size="mini"
                               @click="handleExport" v-has-permission="['sys:resource:export']">导出
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="tableData" style="width: 100%" row-key="id" :highlight-current-row="true"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    :tree-props="{children: 'children'}" v-loading="loading" :default-expand-all="expendAll">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="资源名称"></el-table-column>
                <el-table-column prop="type" label="资源类别" width="80">
                    <template slot-scope="scope">
                        {{ scope.row.type === "C" ? '分类' : scope.row.type === "M" ? '菜单' : '按钮' }}
                    </template>
                </el-table-column>
                <el-table-column prop="icon" label="图标" width="80">
                    <template slot-scope="scope">
                        <i :class="scope.row.icon"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="identifier" label="权限标识"></el-table-column>
                <el-table-column prop="path" label="组件路径"></el-table-column>
                <el-table-column prop="sort" label="排序" width="60"></el-table-column>
                <el-table-column prop="active" label="显示/隐藏" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.active === "1" ? '显示' : '隐藏' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button v-has-permission="['sys:resource:edit']" @click="handleAdd(scope.row)" type="text"
                                   size="small">新增
                        </el-button>
                        <el-button v-has-permission="['sys:resource:edit']" @click="handleEdit(scope.row)" type="text"
                                   size="small">编辑
                        </el-button>
                        <el-button v-has-permission="['sys:resource:delete']" @click="handleDelete(scope.row)"
                                   type="text"
                                   size="small">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>


        <el-dialog :title="title" :visible.sync="dialogFormVisible" :before-close="clearData"
                   :close-on-click-modal="false" width="800px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="父节点" :label-width="formLabelWidth" prop="parentId">
                            <input type="hidden" v-model="form.parentId"/>
                            <mz-tree-select :data="parentSelect.data"
                                            :defaultProps="parentSelect.defaultProps" :multiple="false"
                                            :nodeKey="parentSelect.nodeKey"
                                            :checkedKeys="parentSelect.defaultCheckedKeys"
                                            @change="parentChange"></mz-tree-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="资源类别" :label-width="formLabelWidth" prop="type">
                            <el-select v-model="form.type" placeholder="请选择资源类别" value=""
                                       style="width: 260px;">
                                <el-option label="分类" value="C"></el-option>
                                <el-option label="菜单" value="M"></el-option>
                                <el-option label="按钮" value="B"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="资源名称" :label-width="formLabelWidth" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="资源图标" :label-width="formLabelWidth">
                            <mz-icon-picker :selectedIcon="form.icon" @iconChange="iconChange"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="权限标识" :label-width="formLabelWidth" prop="identifier">
                            <el-input v-model="form.identifier" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="组件路径" :label-width="formLabelWidth">
                            <el-input v-model="form.path" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="排序" :label-width="formLabelWidth">
                            <el-input-number v-model="form.sort" autocomplete="off"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否显示" :label-width="formLabelWidth">
                            <el-switch
                                    v-model="form.active"
                                    active-color="#13ce66"
                                    inactive-color="#efefef">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearData">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.el-input {
    margin-top: 0;
}
</style>

<script>
import MzTreeSelect from "../../../components/TreeSelect";
import MzIconPicker from "@/components/IconPicker";

export default {
    name: "ResourceManage",
    components: {MzIconPicker, MzTreeSelect},
    data() {
        return {
            queryData: {
                name: '',
                active: ''
            },
            tableData: [],
            loading: true,
            dialogFormVisible: false,
            expendAll: false,
            title: '新增资源',
            form: {
                id: '',
                parentId: '',
                name: '',
                identifier: '',
                type: '',
                path: '',
                icon: '',
                sort: '',
                desc: '',
                active: false
            },
            rules: {
                parentId: [
                    {required: true, message: '请选择父节点', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '请选择资源类别', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入资源名称', trigger: 'blur'}
                ],
                identifier: [
                    {required: true, message: '请输入权限标识', trigger: 'blur'}
                ]
            },
            formLabelWidth: '120px',
            parentSelect: {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                nodeKey: 'id',
                defaultCheckedKeys: []
            }
        }
    },
    mounted() {
        this.doQueryList();
    },
    methods: {
        doQueryList() {
            let that = this;
            that.loading = true;
            this.$api.ResourceMod.list(this.queryData).then(function (response) {
                let ret = response.data;
                that.setTableData(ret.data);
                that.loading = false;
            }).catch(function () {
                that.loading = false;
            });
        },
        handleAdd() {
            this.clearData();
            this.title = '新增资源';
            this.dialogFormVisible = true;
            this.loadTree();
        },
        handleDelete(row) {
            let that = this;
            this.$confirm("确定删除[ " + row.name + " ]?", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.ResourceMod.delete(row.id).then(res => {
                    that.$message(res.data.message);
                    that.doQueryList();
                });
            }).catch(() => {
            });
        },
        handleExport() {
            this.dialogFormVisible = true;
            window.console.log('export!', this.queryData);
        },
        handleEdit(row) {
            this.clearData();
            this.loadTree();
            this.title = '编辑资源';
            let rowData = JSON.parse(JSON.stringify(row));
            this.form = rowData;
            this.form.active = "1" === rowData.active;
            let selectedParent = [];
            selectedParent.push('' + rowData.parentId);
            this.parentSelect.defaultCheckedKeys = selectedParent;
            this.dialogFormVisible = true;

        },
        setTableData(dataList) {
            let arr = [];
            for (let i = 0; i < dataList.length; i++) {
                let item = dataList[i];
                if (item.type === 'C') {
                    this.addNext(item, dataList);
                    arr.push(item);
                }
            }
            this.tableData = arr;
        },
        clearData() {
            for (let key in this.form) {
                this.form[key] = null;
            }
            try {
                this.$refs['form'].clearValidate();
            } catch (e) {
                console.warn('no data for clear');
            }
            this.parentSelect.data = [];
            this.parentSelect.defaultCheckedKeys = [];
            this.dialogFormVisible = false;
        },
        addNext(data, dataList) {
            let arr = [];
            for (let i = 0; i < dataList.length; i++) {
                let item = dataList[i];
                if (data.id === item.parentId) {
                    this.addNext(item, dataList);
                    arr.push(item);
                }
            }
            data.children = arr;
        },
        loadTree() {
            let that = this;
            let params = {
                parentId: 0
            };
            this.$api.ResourceMod.tree(params).then(function (response) {
                let ret = response.data;
                that.parentSelect.data = ret.data;
            });
        },
        addOrUpdate(formName) {
            let that = this;
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.form));
                params.active = params.active ? "1" : "0";
                if (params.id === null) {
                    this.$api.ResourceMod.add(params).then(function (response) {
                        that.$message(response.data.message);
                        that.dialogFormVisible = false;
                        that.doQueryList();
                    });
                } else {
                    this.$api.ResourceMod.update(params).then(function (response) {
                        that.$message(response.data.message);
                        that.dialogFormVisible = false;
                        that.doQueryList();
                    });
                }

            });
        },
        iconChange(icon) {
            this.form.icon = icon;
        },
        parentChange(selectData) {
            this.form.parentId = selectData;
        }
    }
}
</script>
