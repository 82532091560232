<template>
    <div>
        <div class="mz-query-content">
            <el-form :inline="true" :model="queryData" class="demo-form-inline query-wrap" @submit.native.prevent>
                <el-form-item label="字典编码" :label-width="formLabelWidth">
                    <el-input v-model="queryData.code" placeholder="请输入字典编码"></el-input>
                </el-form-item>
                <el-form-item label="字典名称" :label-width="formLabelWidth">
                    <el-input v-model="queryData.name" placeholder="请输入字典名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" native-type="submit" @click="doQueryList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="mz-table-content">
            <el-row class="header-button">
                <el-col>
                    <el-button type="primary" icon="el-icon-plus" size="mini"
                               @click="handleAdd" v-has-permission="['sys:dept:add']">新增
                    </el-button>

                    <div style="display: inline-block; margin: 0 10px; height: 12px;">
                        <el-breadcrumb separator=">">
                            <template v-for="(item, key) in breadcrumbList">
                                <el-breadcrumb-item :key="key">
                                    <a href="javascript:void(0);" @click="selectBreadCrumb(item.id)" v-if="key < breadcrumbList.length - 1">{{ item.label }}</a>
                                    <span v-if="key === breadcrumbList.length - 1">{{ item.label }}</span>
                                </el-breadcrumb-item>
                            </template>
                        </el-breadcrumb>
                    </div>
                </el-col>
            </el-row>
            <el-table
                    :data="tableData" style="width: 100%" row-key="id" :highlight-current-row="true"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="loading">
                <el-table-column prop="code" label="字典编码">
                    <template slot-scope="scope">
                        <el-button @click="changeParentId(scope.row)" type="text"  size="medium ">{{  scope.row.code }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="value" label="字典值"></el-table-column>
                <el-table-column prop="desc" label="字典描述"></el-table-column>
                <el-table-column prop="sort" label="排序"></el-table-column>
                <el-table-column prop="active" label="是否有效">
                    <template slot-scope="scope">
                        {{ scope.row.active === "1" ? '有效' : '无效' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button v-has-permission="['sys:dept:edit']" @click="handleEdit(scope.row)" type="text"
                                   size="small">编辑
                        </el-button>
                        <el-button v-has-permission="['sys:dept:delete']" @click="handleDelete(scope.row)" type="text"
                                   size="small">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryData.current"
                    :page-sizes="[10, 20, 50]"
                    :page-size="queryData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.total">
            </el-pagination>
        </div>

        <el-dialog :title="title" :visible.sync="dictFormVisible" :before-close="clearDictForm"
                   :close-on-click-modal="false" width="800px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="字典编码" :label-width="formLabelWidth" prop="code">
                            <el-input v-model="form.code" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="字典值" :label-width="formLabelWidth" prop="value">
                            <el-input v-model="form.value" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="字典描述" :label-width="formLabelWidth" prop="desc">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="排序" :label-width="formLabelWidth">
                            <el-input-number v-model="form.sort" autocomplete="off"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否有效" :label-width="formLabelWidth">
                            <el-switch
                                    v-model="form.active"
                                    active-color="#13ce66"
                                    inactive-color="#efefef">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearDictForm">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.el-input {
    margin-top: 0;
}
</style>

<script>

export default {
    name: "DictManage",
    data() {
        return {
            queryData: {
                parentId: 0,
                name: '',
                code: '',
                current: 1,
                size: 10
            },
            page: {
                total: 0
            },
            tableData: [],
            dictFormVisible: false,
            loading: false,
            title: '新增字典',
            form: {
                id: '',
                code: '',
                value: '',
                desc: '',
                sort: 0,
                active: false
            },
            rules: {
                code: [
                    {required: true, message: '请输入字典编码', trigger: 'blur'}
                ],
                value: [
                    {required: true, message: '请输入字典值', trigger: 'blur'}
                ]
            },
            breadcrumbList : [],
            formLabelWidth: '120px'
        }
    },
    mounted() {
        this.doQueryParentList();
    },
    methods: {
        changeParentId(row) {
            this.queryData.parentId = row.id;
            this.doQueryParentList();
        },
        doQueryParentList() {
            let that = this;
            this.$api.DictMod.parentList(that.queryData.parentId).then(function (response) {
                let ret = response.data;
                let parentList = ret.data;
                parentList = parentList.reverse();
                that.breadcrumbList.splice(parentList.length);
                console.log(parentList.length, )
                for(let i = 0; i < parentList.length; i++){
                    that.breadcrumbList.splice(i, 1, parentList[i]);
                }
                that.doQueryList(1);
            });
        },
        doQueryList(current) {
            let that = this;
            that.loading = true;
            if(typeof current === 'number') {
                this.queryData.current = current;
            }
            this.$api.DictMod.page(this.queryData).then(function (response) {
                let ret = response.data;
                that.tableData = ret.data;
                that.queryData.current = ret.current;
                that.queryData.size = ret.size;
                that.page.total = ret.total;
                that.loading = false;
            }).catch(function () {
                that.loading = false;
            });
        },
        handleAdd() {
            this.clearDictForm();
            this.title = '新增字典';
            this.dictFormVisible = true;
        },
        handleEdit(row) {
            this.clearDictForm();
            this.title = '编辑字典';
            let rowData = JSON.parse(JSON.stringify(row));
            this.form = rowData;
            this.form.active = "1" === rowData.active;
            this.dictFormVisible = true;

        },
        handleDelete(row) {
            let that = this;
            this.$confirm("确定删除[ " + row.name + " ]?", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.DictMod.delete(row.id).then(res => {
                    that.$message(res.data.message);
                    that.doQueryList();
                });
            }).catch(() => {
            });
        },
        clearDictForm() {
            for (let key in this.form) {
                this.form[key] = null;
            }
            try {
                this.$refs['form'].clearValidate();
            } catch (e) {
                console.warn('no data for clear');
            }
            this.dictFormVisible = false;
        },
        addOrUpdate(formName) {
            let that = this;
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.form));
                params.active = params.active ? "1" : "0";
                params.parentId = that.queryData.parentId;
                if (params.id === null) {
                    this.$api.DictMod.add(params).then(function (response) {
                        that.$message(response.data.message);
                        that.dictFormVisible = false;
                        that.doQueryList();
                    });
                } else {
                    this.$api.DictMod.update(params).then(function (response) {
                        that.$message(response.data.message);
                        that.dictFormVisible = false;
                        that.doQueryList();
                    });
                }
            });
        },
        selectBreadCrumb(parentId) {
            this.queryData.parentId = parentId;
            this.doQueryParentList();
        },
        handleSizeChange(pageSize) {
            this.queryData.size = pageSize;
            this.doQueryList(1);
        },
        handleCurrentChange(current) {
            this.doQueryList(current);
        }
    }
}
</script>
