<template>
    <div>
        <div class="mz-query-content">
            <el-form :inline="true" :model="queryData" class="demo-form-inline" @submit.native.prevent>
                <el-form-item label="参数分类" :label-width="formLabelWidth">
                    <el-select clearable v-model="queryData.configType" placeholder="参数分类" value="">
                        <template v-for="item in configTypeList">
                            <el-option :label="item.value" :value="item.code" :key="item.id"
                                       :index="item.id"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="参数编码" :label-width="formLabelWidth">
                    <el-input v-model="queryData.code" placeholder="请输入参数编码"></el-input>
                </el-form-item>
                <el-form-item label="参数名称" :label-width="formLabelWidth">
                    <el-input v-model="queryData.name" placeholder="请输入参数名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" native-type="submit" @click="doQueryList(1)">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="mz-table-content">
            <el-row class="header-button">
                <el-col>
                    <el-button type="primary" icon="el-icon-plus" size="mini"
                               @click="handleAdd" v-has-permission="['sys:config:add']">新增
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="tableData" style="width: 100%" row-key="id" :highlight-current-row="true"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="loading">
                <el-table-column prop="code" label="参数编码"></el-table-column>
                <el-table-column prop="configType" label="参数分类">
                    <template slot-scope="scope">
                        {{transferConfigType(scope.row.configType)}}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="参数名称"></el-table-column>
                <el-table-column prop="value" label="参数值"></el-table-column>
                <el-table-column prop="desc" label="参数描述"></el-table-column>
                <el-table-column prop="active" label="是否有效">
                    <template slot-scope="scope">
                        {{ scope.row.active === "1" ? '有效' : '无效' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button v-has-permission="['sys:config:edit']" @click="handleEdit(scope.row)" type="text"
                                   size="small">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryData.current"
                    :page-sizes="[10, 20, 50]"
                    :page-size="queryData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.total">
            </el-pagination>
        </div>


        <el-dialog :title="title" :visible.sync="configFormVisible" :before-close="clearConfigForm"
                   :close-on-click-modal="false" width="800px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参数分类" :label-width="formLabelWidth" prop="configType">
                            <el-select clearable v-model="form.configType" value="" style="width: 100%;">
                                <template v-for="item in configTypeList">
                                    <el-option :label="item.value" :value="item.code" :key="item.id"
                                               :index="item.id"></el-option>
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参数编码" :label-width="formLabelWidth" prop="code">
                            <el-input v-model="form.code" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参数名称" :label-width="formLabelWidth" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参数值" :label-width="formLabelWidth" prop="value">
                            <el-input v-model="form.value" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参数描述" :label-width="formLabelWidth" prop="desc">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否有效" :label-width="formLabelWidth">
                            <el-switch
                                    v-model="form.active"
                                    active-color="#13ce66"
                                    inactive-color="#efefef">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearConfigForm">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.el-input {
    margin-top: 0;
}
</style>

<script>

export default {
    name: "RoleManage",
    data() {
        return {
            queryData: {
                name: '',
                code: '',
                configType: '',
                current: 1,
                size: 10
            },
            configTypeList: [],
            page: {
                total: 0
            },
            tableData: [],
            configFormVisible: false,
            authFormVisible: false,
            loading: false,
            title: '新增参数',
            form: {
                id: '',
                configType: '',
                code: '',
                name: '',
                value: '',
                desc: '',
                active: false
            },
            rules: {
                configType: [
                    {required: true, message: '请选择参数分类', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '请输入参数编码', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入参数名称', trigger: 'blur'}
                ],
                value: [
                    {required: true, message: '请输入参数值', trigger: 'blur'}
                ]
            },
            formLabelWidth: '120px'
        }
    },
    mounted() {
        this.doQueryConfigTypeList();
    },
    methods: {
        doQueryConfigTypeList() {
            let that = this;
            this.$api.DictMod.listByCode("config_type").then(function (response) {
                that.configTypeList = response.data.data;
                that.doQueryList(1);
            });
        },
        doQueryList(current) {
            let that = this;
            that.loading = true;
            if (typeof current === 'number') {
                this.queryData.current = current;
            }
            this.$api.ConfigMod.page(this.queryData).then(function (response) {
                let ret = response.data;
                that.tableData = ret.data;
                that.queryData.current = ret.current;
                that.queryData.size = ret.size;
                that.page.total = ret.total;
                that.loading = false;
            }).catch(function () {
                that.loading = false;
            });
        },
        handleAdd() {
            this.clearConfigForm();
            this.title = '新增参数';
            this.configFormVisible = true;
        },
        handleEdit(row) {
            this.clearConfigForm();
            this.title = '编辑参数';
            let rowData = JSON.parse(JSON.stringify(row));
            this.form = rowData;
            this.form.active = "1" === rowData.active;
            this.configFormVisible = true;
        },

        clearConfigForm() {
            for (let key in this.form) {
                this.form[key] = null;
            }
            this.configFormVisible = false;
            try {
                this.$refs['form'].clearValidate();
            } catch (e) {
                console.warn('no data for clear');
            }
        },

        addOrUpdate(formName) {
            let that = this;
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.form));
                params.active = params.active ? "1" : "0";
                if (params.id === null) {
                    this.$api.ConfigMod.add(params).then(function (response) {
                        that.$message(response.data.message);
                        that.configFormVisible = false;
                        that.doQueryList();
                    });
                } else {
                    this.$api.ConfigMod.update(params).then(function (response) {
                        that.$message(response.data.message);
                        that.configFormVisible = false;
                        that.doQueryList();
                    });
                }
            });
        },
        handleSizeChange(pageSize) {
            this.queryData.size = pageSize;
            this.doQueryList(1);
        },
        handleCurrentChange(current) {
            this.doQueryList(current);
        },
        transferConfigType(typeCode) {
            for (let i = 0; i < this.configTypeList.length; i++) {
                if (this.configTypeList[i].code === typeCode) {
                    return this.configTypeList[i].value;
                }
            }
            return typeCode;
        }
    }
}
</script>
