<template>
    <div>
        <div class="mz-query-content">
            <el-form :inline="true" :model="queryData" class="demo-form-inline query-wrap" @submit.native.prevent>
                <el-form-item label="角色编码" :label-width="formLabelWidth">
                    <el-input v-model="queryData.code" placeholder="请输入角色编码"></el-input>
                </el-form-item>
                <el-form-item label="角色名称" :label-width="formLabelWidth">
                    <el-input v-model="queryData.name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" native-type="submit" @click="doQueryList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="mz-table-content">
            <el-row class="header-button">
                <el-col>
                    <el-button type="primary" icon="el-icon-plus" size="mini"
                               @click="handleAdd" v-has-permission="['sys:role:add']">新增
                    </el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="tableData" style="width: 100%" row-key="id" :highlight-current-row="true"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="loading">
                <el-table-column prop="code" label="角色编码"></el-table-column>
                <el-table-column prop="name" label="角色名称"></el-table-column>
                <el-table-column prop="desc" label="角色描述"></el-table-column>
                <el-table-column prop="active" label="是否有效">
                    <template slot-scope="scope">
                        {{ scope.row.active === "1" ? '有效' : '无效' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button v-has-permission="['sys:role:auth']" @click="handleAuth(scope.row)" type="text"
                                   size="small">授权
                        </el-button>
                        <el-button v-has-permission="['sys:role:edit']" @click="handleEdit(scope.row)" type="text"
                                   size="small">编辑
                        </el-button>
                        <el-button v-has-permission="['sys:role:delete']" @click="handleDelete(scope.row)" type="text"
                                   size="small">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryData.current"
                    :page-sizes="[10, 20, 50]"
                    :page-size="queryData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.total">
            </el-pagination>
        </div>

        <el-dialog :title="title" :visible.sync="roleFormVisible" :before-close="clearRoleForm"
                   :close-on-click-modal="false" width="800px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="角色编码" :label-width="formLabelWidth" prop="code">
                            <el-input v-model="form.code" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="角色名称" :label-width="formLabelWidth" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="角色描述" :label-width="formLabelWidth" prop="desc">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否有效" :label-width="formLabelWidth">
                            <el-switch
                                    v-model="form.active"
                                    active-color="#13ce66"
                                    inactive-color="#efefef">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearRoleForm">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.el-input {
    margin-top: 0;
}
</style>

<script>

export default {
    name: "RoleManage",
    data() {
        return {
            queryData: {
                name: '',
                code: '',
                current: 1,
                size: 10
            },
            page: {
                total: 0
            },
            tableData: [],
            roleFormVisible: false,
            authFormVisible: false,
            loading: false,
            title: '新增角色',
            form: {
                id: '',
                code: '',
                name: '',
                desc: '',
                active: false
            },
            rules: {
                code: [
                    {required: true, message: '请输入角色编码', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入角色名称', trigger: 'blur'}
                ]
            },
            formLabelWidth: '120px'
        }
    },
    mounted() {
        this.doQueryList(1);
    },
    methods: {
        doQueryList(current) {
            let that = this;
            that.loading = true;
            if (typeof current === 'number') {
                this.queryData.current = current;
            }
            this.$api.RoleMod.page(this.queryData).then(function (response) {
                let ret = response.data;
                that.tableData = ret.data;
                that.queryData.current = ret.current;
                that.queryData.size = ret.size;
                that.page.total = ret.total;
                that.loading = false;
            }).catch(function () {
                that.loading = false;
            });
        },
        handleAdd() {
            this.clearRoleForm();
            this.title = '新增角色';
            this.roleFormVisible = true;
        },
        handleAuth(row) {
            window.console.log(row)
            this.clearAuthForm();
            this.title = '角色授权';
            this.authFormVisible = true;
        },
        handleEdit(row) {
            this.clearRoleForm();
            this.title = '编辑角色';
            let rowData = JSON.parse(JSON.stringify(row));
            this.form = rowData;
            this.form.active = "1" === rowData.active;
            this.roleFormVisible = true;

        },
        handleDelete(row) {
            let that = this;
            this.$confirm("确定删除[ " + row.name + " ]?", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.RoleMod.delete(row.id).then(res => {
                    that.$message(res.data.message);
                    that.doQueryList();
                });
            }).catch(() => {
            });
        },
        clearRoleForm() {
            for (let key in this.form) {
                this.form[key] = null;
            }
            this.roleFormVisible = false;
        },
        clearAuthForm() {

        },
        addOrUpdate(formName) {
            let that = this;
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.form));
                params.active = params.active ? "1" : "0";
                if (params.id === null) {
                    this.$api.RoleMod.add(params).then(function (response) {
                        that.$message(response.data.message);
                        that.roleFormVisible = false;
                        that.doQueryList();
                    });
                } else {
                    this.$api.RoleMod.update(params).then(function (response) {
                        that.$message(response.data.message);
                        that.roleFormVisible = false;
                        that.doQueryList();
                    });
                }
            });
        },
        handleSizeChange(pageSize) {
            this.queryData.size = pageSize;
            this.doQueryList(1);
        },
        handleCurrentChange(current) {
            this.doQueryList(current);
        }
    }
}
</script>
