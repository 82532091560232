<template>
    <div>
        <div class="mz-tree-left" style="display:inline-block;float: left;">
            <el-button type="primary" icon="el-icon-refresh" size="mini" plain
                       @click="handleRefreshDept">刷新
            </el-button>
            <el-tree :data="deptTree" :props="treeProps" @node-click="handleDeptClick" ref="deptTree"
                     :highlight-current="true" :expand-on-click-node="false" :default-expand-all="true">
            </el-tree>
        </div>

        <div class="mz-tree-right">
            <div class="mz-query-content">
                <el-form :inline="true" :model="queryData" class="demo-form-inline" @submit.native.prevent>
                    <el-form-item label="用户名" :label-width="formLabelWidth">
                        <el-input v-model="queryData.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                        <el-input v-model="queryData.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" native-type="submit" @click="doQueryList">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="mz-table-content">
                <el-row class="header-button">
                    <el-col>
                        <el-button type="primary" icon="el-icon-plus" size="mini"
                                   @click="handleAdd" v-has-permission="['sys:role:add']">新增
                        </el-button>
                    </el-col>
                </el-row>
                <el-table
                        :data="tableData" style="width: 100%" row-key="id" :highlight-current-row="true"
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="loading">
                    <el-table-column prop="username" label="用户名"></el-table-column>
                    <el-table-column prop="nickName" label="昵称"></el-table-column>
                    <el-table-column prop="jobNumber" label="岗位编号"></el-table-column>
                    <el-table-column prop="sex" label="性别">
                        <template slot-scope="scope">
                            {{ scope.row.sex === "1" ? '男' : '女' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="superAdmin" label="超级管理员">
                        <template slot-scope="scope">
                            {{ scope.row.superAdmin === "1" ? '是' : '否' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            {{ scope.row.status === "Normal" ? '正常' : scope.row.status === "Locked" ? '锁定' : '删除'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastLoginIp" label="最后登录IP"></el-table-column>
                    <el-table-column prop="lastLoginTime" label="最后登录时间"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button v-has-permission="['sys:role:auth']" @click="handleAuth(scope.row)" type="text"
                                       size="small">授权
                            </el-button>
                            <el-button v-has-permission="['sys:role:edit']" @click="handleEdit(scope.row)" type="text"
                                       size="small">编辑
                            </el-button>
                            <el-button :disabled="scope.row.superAdmin === '1'" v-has-permission="['sys:role:delete']" @click="handleDelete(scope.row)"
                                       type="text"
                                       size="small">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>


        <el-dialog :title="title" :visible.sync="userFormVisible" :before-close="clearUserForm"
                   :close-on-click-modal="false" width="960px">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门" :label-width="formLabelWidth" prop="deptId">
                            <input type="hidden" v-model="form.deptId"/>
                            <mz-tree-select :data="deptSelect.data" :width="320"
                                            :defaultProps="deptSelect.defaultProps" :multiple="false"
                                            :nodeKey="deptSelect.nodeKey"
                                            :checkedKeys="deptSelect.defaultCheckedKeys"
                                            @change="parentChange"></mz-tree-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="岗位编号" :label-width="formLabelWidth" prop="jobNumber">
                            <el-input v-model="form.jobNumber" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="用户名" :label-width="formLabelWidth" prop="username">
                            <el-input v-model="form.username" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户昵称" :label-width="formLabelWidth" prop="nickName">
                            <el-input v-model="form.nickName" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                            <el-input v-model="form.phone" autocomplete="off" maxlength="11"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生日" :label-width="formLabelWidth" prop="birthday">
                            <el-date-picker
                                    v-model="form.birthday"
                                    format="yyyy-MM-dd"
                                    type="date" style="width: 340px;"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="邮箱" :label-width="formLabelWidth" prop="desc">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="头像" :label-width="formLabelWidth" prop="desc">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="状态" :label-width="formLabelWidth">
                            <template>
                                <el-radio-group v-model="form.status">
                                    <el-radio label="Normal">正常</el-radio>
                                    <el-radio label="Locked">锁定</el-radio>
                                    <el-radio label="Deleted">删除</el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" :label-width="formLabelWidth" prop="sex">
                            <template>
                                <el-radio v-model="form.sex" label="1" >男</el-radio>
                                <el-radio v-model="form.sex" label="2">女</el-radio>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="超级管理员" :label-width="formLabelWidth">
                            <el-switch
                                    v-model="form.superAdmin"
                                    active-color="#13ce66"
                                    inactive-color="#efefef">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clearUserForm">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.el-input {
    margin-top: 0;
}
</style>

<script>

import MzTreeSelect from "@/components/TreeSelect";

export default {
    name: "RoleManage",
    components: {MzTreeSelect},
    data() {
        return {
            queryData: {
                name: '',
                code: '',
                deptId : ''
            },
            tableData: [],
            userFormVisible: false,
            authFormVisible: false,
            loading: false,
            title: '新增用户',
            deptSelect: {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                nodeKey: 'id',
                defaultCheckedKeys: []
            },
            form: {
                id: '',
                username: '',
                deptId: '',
                jobNumber: '',
                nickName: '',
                birthday: '',
                phone: '',
                sex: '',
                email: '',
                superAdmin: false,
                avatar: '',
                status: ''
            },
            rules: {
                deptId: [
                    {required: true, message: '请选择部门', trigger: 'blur'}
                ],
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'}
                ],
                jobNumber: [
                    {required: true, message: '请输入岗位编号', trigger: 'blur'}
                ]
            },
            formLabelWidth: '120px',
            deptTree: [],
            treeProps: {
                children: 'children',
                label: 'title'
            }
        };
    },
    mounted() {
        this.initDeptTree();
        this.doQueryList();
    },
    methods: {
        initDeptTree() {
            let that = this;
            this.$api.DeptMod.tree({parentId: 0}).then(function (response) {
                let data = response.data.data;
                that.deptTree = data;
                that.deptSelect.data = data;
            });
        },
        handleRefreshDept() {
            this.initDeptTree();
        },
        handleDeptClick(data) {
            this.queryData.deptId = data.id;
            this.doQueryList();
        },
        doQueryList() {
            let that = this;
            that.loading = true;
            this.$api.UserMod.page(this.queryData).then(function (response) {
                let ret = response.data;
                that.tableData = ret.data;
                that.loading = false;
            }).catch(function () {
                that.loading = false;
            });
        },
        handleAdd() {
            this.clearUserForm();
            this.title = '新增用户';
            this.userFormVisible = true;
            let selectedParent = [];
            selectedParent.push(this.queryData.deptId);
            this.deptSelect.defaultCheckedKeys = selectedParent;
            this.form.deptId = this.queryData.deptId;
            this.form.sex = '1';
            this.form.status = 'Normal';
        },
        handleAuth(row) {
            window.console.log(row)
            this.clearAuthForm();
            this.title = '用户授权';
            this.authFormVisible = true;
        },
        handleEdit(row) {
            this.clearUserForm();
            this.title = '编辑用户';
            let rowData = JSON.parse(JSON.stringify(row));
            this.form = rowData;
            this.form.superAdmin = "1" === rowData.superAdmin;
            this.form.active = "1" === rowData.active;
            this.userFormVisible = true;
            let selectedParent = [];
            selectedParent.push(rowData.deptId);
            this.deptSelect.defaultCheckedKeys = selectedParent;

        },
        handleDelete(row) {
            let that = this;
            this.$confirm("确定删除[ " + row.username + " ]?", "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.UserMod.delete(row.id).then(res => {
                    that.$message(res.data.message);
                    that.doQueryList();
                });
            }).catch(() => {
            });
        },
        clearUserForm() {
            for (let key in this.form) {
                this.form[key] = null;
            }
            this.userFormVisible = false;
        },
        clearAuthForm() {

        },
        addOrUpdate(formName) {
            let that = this;
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.form));
                params.superAdmin = params.superAdmin ? "1" : "0";
                if (params.id === null) {
                    this.$api.UserMod.add(params).then(function (response) {
                        that.$message(response.data.message);
                        that.userFormVisible = false;
                        that.doQueryList();
                    });
                } else {
                    this.$api.UserMod.update(params).then(function (response) {
                        that.$message(response.data.message);
                        that.userFormVisible = false;
                        that.doQueryList();
                    });
                }
            });
        },
        parentChange(selectData) {
            this.form.deptId = selectData;
        }
    }
}
</script>
